import Vue from 'vue'
import VueRouter from 'vue-router'
const Layout = () => import('../layout/index.vue')
const Index = () => import('../views/Index.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect:'/index',
    children:[
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/Index.vue')
      },
      {
        path: 'lb',
        name: 'lb',
        component: () => import('../views/lb.vue')
      },
      {
        path: '/solution',
        name: 'solution',
        component: () => import('../views/solution.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('../views/service.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/Product.vue')
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('../views/monitor.vue')
      },
      {
        path: '/Journalism',
        name: 'Journalism',
        component: () => import('../views/Journalism.vue')
      },  
      {
        path: '/Journalism1',
        name: 'Journalism1',
        component: () => import('../views/Journalism1.vue')
      },  
      {
        path: '/views/productClass',
        name: 'productClass',
        component: () => import('../views/ProductClass.vue')
      },
      {
        path: '/views/product-2',
        name: 'product-2',
        component: () => import('../views/product-2.vue')
      },
      {
        path: '/views/service-2',
        name: 'service-2',
        component: () => import('../views/service-2.vue')
      },      
      {
        path: '/views/service-1',
        name: 'service-1',
        component: () => import('../views/service-1.vue')
      },         
      {
        path: '/views/solution1',
        name: 'solution1',
        component: () => import('../views/solution1.vue')
      },

    ]
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
