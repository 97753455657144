import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import './styles/main.css'
import BaiduMap from 'vue-baidu-map'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(BaiduMap, {
  ak: "i8XO3w6VnL1KGkrgwIH8C8qAhatemcXD"
})
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
  
  
}).$mount('#app')
axios.defaults.withCredentials = true
axios.defaults.headers.common['accessToken'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3V1aWQiOiJjY2QxLTg4MGEtNDM0Ni04MzZlLTljMDQ1N2FiN2VmZCIsInVzZXJOYW1lIjoiYWRtaW4iLCJleHAiOjE2MDY5NzM2MDB9.QaJAPBdLLpZYlgGVUgFspWDOEXJIV0Wt-2pNzqx-66k.eyJ1c2VyX3V1aWQiOiJjY2QxLTg4MGEtNDM0Ni04MzZlLTljMDQ1N2FiN2VmZCIsInVzZXJOYW1lIjoiYWRtaW4iLCJleHAiOjE2MDUyMzM4MTV9.1UIUe3cOVENzggbvq0EdMjhKKFTVSQ1qs-MeUvG1pOI"
Vue.prototype.$axios = axios
axios.interceptors.request.use(config => {
  config.headers.common['accessToken'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3V1aWQiOiJjY2QxLTg4MGEtNDM0Ni04MzZlLTljMDQ1N2FiN2VmZCIsInVzZXJOYW1lIjoiYWRtaW4iLCJleHAiOjE2MDUyMzM4MTV9.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3V1aWQiOiJjY2QxLTg4MGEtNDM0Ni04MzZlLTljMDQ1N2FiN2VmZCIsInVzZXJOYW1lIjoiYWRtaW4iLCJleHAiOjE2MDY5NzM2MDB9.QaJAPBdLLpZYlgGVUgFspWDOEXJIV0Wt-2pNzqx-66k-MeUvG1pOI"
  return config
})
router.afterEach(() => {
  window.scrollTo(0,0);
})

// router.afterEach((to,from,next) => {
// 	document.body.scrollTop =0;
// 	document.documentElement.scrollTop = 0;
// });


